import { defineStore } from "pinia";

export const useStoryblokStore = defineStore(
  "storyblok",
  () => {
    const state: object = reactive({
      runtimeConfig: null,
      config: null,
      configLoaded: false,
      links: null,
      blockquotes: [],
      faqs: [],
      solutions: [],
    });

    function reset() {
      state.runtimeConfig = null;
      state.config = null;
      state.configLoaded = false;
      state.links = null;
      state.blockquotes = [];
      state.faqs = [];
      state.solutions = [];
    }

    return {
      ...toRefs(state),
      reset,
    };
  },
  { persist: true },
);
