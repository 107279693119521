import { default as _91_46_46_46slug_93gGbPnl9WvbMeta } from "/Users/dennisdarko/Sites/zweikern-website_v2/pages/[...slug].vue?macro=true";
import { default as component_45stubKyfBuAeeZ9Meta } from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubKyfBuAeeZ9 } from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/Users/dennisdarko/Sites/zweikern-website_v2/pages/[...slug].vue")
  },
  {
    name: "slug___de",
    path: "/:slug(.*)*",
    component: () => import("/Users/dennisdarko/Sites/zweikern-website_v2/pages/[...slug].vue")
  },
  {
    name: component_45stubKyfBuAeeZ9Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubKyfBuAeeZ9
  },
  {
    name: component_45stubKyfBuAeeZ9Meta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubKyfBuAeeZ9
  },
  {
    name: component_45stubKyfBuAeeZ9Meta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubKyfBuAeeZ9
  },
  {
    name: component_45stubKyfBuAeeZ9Meta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubKyfBuAeeZ9
  }
]