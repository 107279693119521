<script setup lang="ts">
import StoryblokClient from "storyblok-js-client";

const runtimeConfig = useState("runtimeConfig");
const websiteConfig = useState("config");
const storyblokStore = useStoryblokStore();
const { $store } = useNuxtApp();

const { locale } = useI18n();
// storyblokStore.reset();

if (!storyblokStore.runtimeConfig) {
  runtimeConfig.value = useRuntimeConfig();
  storyblokStore.runtimeConfig = runtimeConfig.value;
} else {
  runtimeConfig.value = storyblokStore.runtimeConfig;
}

const config = runtimeConfig.value;

if (!storyblokStore.links) {
  await useStoryblokI18nLinks();
  // console.log("setLinks", $store.getters.getLinks);
}

$store.dispatch("setLinks", storyblokStore.links);

if (!storyblokStore.configLoaded) {
  try {
    const Storyblok = new StoryblokClient({
      accessToken: config.public.storyblok.accessToken,

      cache: {
        clear: "auto",
        type: "memory",
      },
      maxRetries: 20,
    });

    await Storyblok.get("cdn/stories/config", {
      // resolve_links: "url",
      ...config.public.storyblok.apiOptions,
      language: locale.value,
    })
      .then((response) => {
        storyblokStore.config = response;
        storyblokStore.configLoaded = 1;
      })
      .catch((error) => {
        console.error("app.vue", error);
      });
  } catch (error) {
    console.error("error", error);
  }
}

websiteConfig.value = storyblokStore.config;

const hidden = ref(true);
onMounted(() => {
  hidden.value = false;
});
</script>

<template>
  <div :class="{ 'opacity-0': hidden }">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
