import StoryblokClient from "storyblok-js-client";

export const useStoryblokI18nLinks = async () => {
  const { $store } = useNuxtApp();
  const storyblokApi = useStoryblokApi();
  const storyblokApiOptions = useStoryblokApiOptions();
  const storyblokLinks = ref(null);
  const storyblokStore = useStoryblokStore();
  const config = useRuntimeConfig();

  const runtimeConfig = storyblokStore.runtimeConfig;

  const Storyblok = new StoryblokClient({
    accessToken: runtimeConfig.public.storyblok.accessToken,

    cache: {
      clear: "auto",
      type: "memory",
    },
    maxRetries: 20,
  });

  await Storyblok.get("cdn/links", {
    // resolve_links: "url",
    ...config.public.storyblok.apiOptions,
    per_page: 800,
  })
    .then((response) => {
      storyblokStore.links = response.data.links;
    })
    .catch((error) => {
      console.error("useStoryblokI18nLinks", error);
    });

  // const { data: links } = await storyblokApi.get("cdn/links", {
  //   ...storyblokApiOptions,
  //   resolve_links: "url",
  //   per_page: 800,
  // });

  // storyblokStore.links = links.links;

  return storyblokStore.links;
};
